<template>
  <!-- 本组件 后台端跟业务端 应保持同步 -->
  <div v-if="isPaidOrder(data)">
    <!-- 最开始支付的货款 -->
    <div>产品货款: {{ parsePrice(data.internalTotalPrice) }}</div>
    <!-- 最开始支付的运费 -->
    <div>运费: {{ freightFormatter(data) }}</div>
    <template v-if="data.$diffFreightList">
      <div
        v-for="(item, index) in data.$diffFreightList"
        :key="index"
      >退补运费: {{ item }}</div>
    </template>
    <div>优惠: {{ parsePrice(data.couponAmount) }}</div>
    <div>
      总计:
      <questionTooltip v-if="hasTip" :content="`总计 = 产品货款 + 运费${data.$diffFreightList ? ' + 退补运费' : ''} - 优惠`"></questionTooltip>
      {{ totalPriceFormatter(data) }}
    </div>
  </div>
  <div v-else>
    <div>产品货款: {{ parsePrice(data.internalTotalPrice) }}</div>
    <div>运费:{{ freightFormatter(data) }}</div>
    <div>总计: {{ totalPriceFormatter(data) }}</div>
  </div>
</template>

<script>
import questionTooltip from '@/views/components/base/questionTooltip'
import { getFreight, getTotalPrice, isPaidOrder } from '@/views/order/utils'
import { parsePrice } from '@/utils'

export default {
  components: {
    questionTooltip
  },
  props: {
    data: {
      default: () => ({})
    },
    hasTip: Boolean
  },
  watch: {
    data: {
      handler(data) {
        let { changeRecordDTOList } = data
        if (isPaidOrder(data) && changeRecordDTOList?.length > 1) {
          data.diffFreightList = []
          changeRecordDTOList.slice(1).forEach((item, index) => {
            data.diffFreightList.push(
              this.$math.subtract(
                item.orderDTOInfoNew.realTemplateFreight,
                changeRecordDTOList[index].orderDTOInfoNew.realTemplateFreight
              )
            )
          })
          data.$diffFreightList = data.diffFreightList
            .filter(item => item !== 0)
            .map(item => `${item > 0 ? '+' : ''}${item}`)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    isPaidOrder,
    parsePrice,
    freightFormatter(data) {
      return data.$realTemplateFreight = getFreight(data)
    },
    totalPriceFormatter(data) {
      return data.$totalPrice = getTotalPrice(data)
    }
  }
}
</script>