var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-warpper",attrs:{"append-to-body":"","visible":_vm.dialogVisible,"uiid":"zd-audit-dialog","title":"审核","width":"972px","top":"8vh"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"closed":_vm.dialogClose},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.showBtn)?_c('comfirmDialog',{attrs:{"title":_vm.confirmMsg[_vm.HANDLE_STATUS_REFUSE_BY_CUSTOMER],"initValue":{
        amount: _vm.form.amount,
        compensateType: _vm.form.compensateType,
        detail: _vm.detail,
        curType: _vm.curType,
        type: _vm.type,
        handleStatus: _vm.statusDict[0]
      },"openValidate":_vm.validate,"uiid":"zd-audit-dialog-reject"},on:{"submit":_vm.submit}}):_vm._e(),(_vm.showBtn && _vm.type != 1)?_c('comfirmDialog',{attrs:{"title":"退回","initValue":{
        paymentType: _vm.form.paymentType,
        amount: _vm.form.amount,
        compensateType: _vm.form.compensateType,
        detail: _vm.detail,
        curType: _vm.curType,
        type: _vm.type,
        handleStatus: _vm.statusDict[1]
      },"openValidate":_vm.validate,"uiid":"zd-audit-dialog-return"},on:{"submit":_vm.submit}}):_vm._e(),(_vm.showBtn)?_c('comfirmDialog',{attrs:{"btnType":"primary","title":_vm.confirmMsg[_vm.HANDLE_STATUS_SUCCESS_BY_CUSTOMER],"initValue":{
        paymentType: _vm.form.paymentType,
        amount: _vm.form.amount,
        compensateType: _vm.form.compensateType,
        detail: _vm.detail,
        curType: _vm.curType,
        type: _vm.type,
        handleStatus: _vm.statusDict[2]
      },"openValidate":_vm.validate,"uiid":"zd-audit-dialog-confirm"},on:{"submit":_vm.submit}}):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"form-tabs-table table-header-border form-item-mb0"},[_c('avue-form',{ref:"form",attrs:{"option":_vm.formOption},scopedSlots:_vm._u([{key:"compensateType",fn:function(){return [_vm._v(" "+_vm._s(_vm.findByvalue(_vm.compensateType, _vm.form.compensateType))+" ")]},proxy:true},{key:"deliveryAddress",fn:function(){return [_vm._v(" "+_vm._s(_vm.form.contactAddress)+" "),_c('span',{staticClass:"ml50"},[_vm._v(_vm._s(_vm.form.contactName)+" "+_vm._s(_vm.form.contactMobile))])]},proxy:true},{key:"receiptFilePathList",fn:function(){return [_c('defaultImg',{staticStyle:{"height":"40px"},attrs:{"disabled":"","preview-src-list":_vm.form.receiptFilePathList || [],"carouselList":_vm.form.receiptFilePathList || []}})]},proxy:true}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.tabList),function(item){return _c('el-tab-pane',{key:item.value,attrs:{"label":item.label,"name":item.value}},[(item.value == 'approveInfo')?_c('div',{staticStyle:{"padding-top":"6px"}},[_c('el-timeline',{attrs:{"reverse":_vm.reverse}},_vm._l((_vm.approveInfo),function(activity,index){return _c('el-timeline-item',{key:index,attrs:{"timestamp":activity.timestamp}},[_vm._v(" "+_vm._s(activity.content)+" ")])}),1)],1):(_vm.data[item.value])?_vm._l((item.tableAttrs),function(tableAttr,index){return _c('customTable',_vm._b({key:index,attrs:{"data":_vm.data[item.value][index]},scopedSlots:_vm._u([{key:"menuLeft",fn:function(){return [_vm._v(" "+_vm._s(tableAttr.header)+" ")]},proxy:true},{key:"productInfoText",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"ml20 nowrap"},[_c('div',[_vm._v(_vm._s(row.productCustomSkuCode || '暂无')+" x "+_vm._s(row.productCount))]),_c('div',[_vm._v(_vm._s(row.productCnName))]),_c('div',[_vm._v("款式："+_vm._s(row.productStructName))]),_c('div',[_vm._v("颜色："+_vm._s(row.colorName || '暂无'))]),_c('div',[_vm._v("尺码："+_vm._s(row.productSizeName))]),_c('div',[_vm._v("单价："+_vm._s(row.$internalUnitPrice))])])]}},{key:"salesAmount",fn:function(ref){
      var row = ref.row;
return [_c('totalLoans',{attrs:{"data":row.orderInfo}})]}},{key:"consigneeInfo",fn:function(ref){
      var row = ref.row;
return [(!_vm.isFBAOrder)?_c('div',[_c('div',[_vm._v(_vm._s(row.consigneeName))]),_c('div',[_vm._v("【"+_vm._s(row.countryCnName)+"】")])]):_c('div',[_c('div',[_vm._v(_vm._s(_vm.initWaybillApplyRecord(row).consigneeName))]),_c('div',[_vm._v("【"+_vm._s(_vm.initWaybillApplyRecord(row).consigneeCountryCode)+"】")])])]}},{key:"expressInfo",fn:function(ref){
      var row = ref.row;
return [(!_vm.isFBAOrder)?[_c('div',[_vm._v(_vm._s(row.expressCompanyName))]),_c('div',[_c('span',[_vm._v(_vm._s(row.cnName || row.shippingMethodName))])]),(row.expressWaybillCode)?_c('div',[_vm._v(" 「"),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(row.expressWaybillCode))]),_vm._v("」 ")]):_vm._e(),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.expressExpireTimeDetail(row.expressExpireTime)))])]:_c('div',[_c('div',[_c('span',[_vm._v(_vm._s(_vm.initWaybillApplyRecord(row).expressShippingMethodCnName))])]),(_vm.initWaybillApplyRecord(row).expressWaybillCode)?_c('div',[_vm._v(" 「"),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.initWaybillApplyRecord(row).expressWaybillCode))]),_vm._v("」 ")]):_vm._e()])]}}],null,true)},'customTable',tableAttr,false))}):_vm._e()],2)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }