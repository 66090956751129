/* eslint-disable no-undef */
import { producedAfterSaleAudit } from '@/api/financial/afterSaleProcessingOfOrders'
import { validators } from '@/utils'

export default {
  computed: {
    producedOption() {
      return {
        option: {
          span: 8,
          menuBtn: false,
          column: [
            {
              label: '退款编号',
              prop: 'tradeNo',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '订单号',
              prop: 'orderCode',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '联系方式',
              prop: 'businessContactMobile',
              type: 'text'
            },
            {
              label: '处理方式',
              prop: '$handleType',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '物流方式',
              prop: 'shippingMethodName',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '补缴运费',
              prop: 'realFreight',
              type: 'text',
              placeholder: '￥0',
              formatter(val) {
                return `￥${val}`
              }
            },
            {
              label: '收货地址',
              prop: 'deliveryAddress',
              span: 24,
              formslot: true
            },
            {
              label: '退款金额',
              prop: 'amount',
              placeholder: '暂无',
              type: 'clickInput',
              formatter(val) {
                return `￥${val}`
              },
              rules: [
                {
                  validator: validators.money
                }
              ]
            },
            {
              label: '退款方式',
              prop: 'refundType',
              type: 'radio'
            }
            // {
            //   label: '备注',
            //   prop: 'refundRemark',
            //   placeholder: '单行输入',
            //   span: 16,
            //   type: 'textarea',
            //   minRows: 4,
            //   showWordLimit: true,
            //   maxlength: 100
            // }
          ]
        },
        doFn: async () => {
          let { form, oForm } = this
          let refundRemark = form.refundRemark
          form = this.validateDiffData(form, oForm)
          form.id = oForm.id
          form.refundRemark = refundRemark
          return producedAfterSaleAudit(form)
        }
      }
    }
  }
}
