import { PAYMENT_TYPE_SYS_DIC } from '@/utils/constant'
import { accSub, accAdd, parsePrice } from '@/utils'

export const tabs = [
  {
    label: '订单信息',
    value: 'orderInfo',
    tableAttrs: [
      {
        option: {
          maxHeight: 373,
          topPage: false,
          page: false,
          menu: false,
          column: [
            {
              label: '产品信息',
              prop: 'productInfo',
              minWidth: 125,
              type: 'imgText',
              srcProp: 'productShowCover',
              imgAttr: {
                width: '100',
                height: '100'
              }
            },
            {
              label: '销售金额',
              prop: 'salesAmount',
              slot: true
            },
            {
              label: '收件人信息',
              prop: 'consigneeInfo',
              slot: true
            },
            {
              label: '物流信息',
              prop: 'expressInfo',
              slot: true
            }
          ]
        },
        spanMethod({ row, rowIndex, columnIndex }) {
          if (columnIndex > 0) {
            if (rowIndex > 0) {
              return [0, 0]
            } else {
              return [row.$orderItemLen, 1]
            }
          }
          return [1, 1]
        }
      }
    ]
  },
  {
    label: '退款产品',
    value: 'refundInfo',
    tableAttrs: [
      {
        option: {
          maxHeight: 373,
          topPage: false,
          page: false,
          menu: false,
          column: [
            {
              label: '产品图片',
              prop: 'refundInfo',
              minWidth: 120,
              type: 'image',
              srcProp: 'productShowCover',
              imgAttr: {
                width: '100',
                height: '100'
              }
            },
            {
              label: '产品名称',
              prop: 'productCnName'
            },
            {
              label: 'SKU',
              prop: 'productCustomSkuCode'
            },
            {
              label: '数量',
              prop: 'productCount'
            },
            {
              label: '款式',
              prop: 'productStructName'
            },
            {
              label: '颜色',
              prop: 'colorName'
            },
            {
              label: '尺码',
              prop: 'productSizeName'
            },
            {
              label: '单价',
              prop: '$internalUnitPrice'
            }
          ]
        }
      }
    ]
  },
  {
    label: '支付信息',
    value: 'payInfo',
    tableAttrs: [
      {
        header: '支付订单',
        option: {
          topPage: false,
          page: false,
          menu: false,
          column: [
            {
              label: '总金额',
              prop: 'totalPrice',
              formatter({ $payAmount }) {
                return $payAmount.$realAmount
              }
            },
            {
              label: '货款',
              prop: 'internalTotalPrice',
              formatter({ $payAmount }) {
                return $payAmount.$internalTotalPrice
              }
            },
            {
              label: '运费',
              prop: 'freight',
              formatter({ $payAmount }) {
                return $payAmount.$freight
              }
            },
            {
              label: '优惠金额',
              prop: 'couponAmount',
              formatter({ $payAmount }) {
                return $payAmount.$couponAmount
              }
            },
            {
              label: '实付金额',
              prop: 'realAmount',
              formatter({ $payAmount }) {
                return $payAmount.$realAmount
              }
            },
            {
              label: '支付方式',
              prop: 'paymentType',
              dicData: PAYMENT_TYPE_SYS_DIC
            },
            {
              label: '支付流水号',
              prop: 'thirdpartTradeNo'
            }
          ]
        }
      },
      {
        header: '重新申请运单',
        option: {
          maxHeight: 218,
          topPage: false,
          page: false,
          menu: false,
          column: [
            {
              label: '申请前物流',
              prop: 'beforeExpress',
              formatter(row) {
                return `${row.oExpressCompanyName}(${parsePrice(row.oRealTemplateFreight)})`
              }
            },
            {
              label: '申请后物流',
              prop: 'afterExpress',
              formatter(row) {
                return `${row.expressCompanyName}(${parsePrice(row.realTemplateFreight)})`
              }
            },
            {
              label: '退补金额',
              prop: 'diffFreight',
              formatter(row) {
                if (!row.$diffFreight) {
                  row.diffFreight = accSub(row.realTemplateFreight, row.oRealTemplateFreight)
                  row.$diffFreight = `${row.diffFreight > 0 ? '+' : ''}${row.diffFreight}`
                }
                return row.$diffFreight
              }
            },
            {
              label: '支付方式',
              prop: 'paymentType',
              dicData: PAYMENT_TYPE_SYS_DIC
            }
          ]
        }
      }
    ]
  },
  {
    label: '审核信息',
    value: 'approveInfo'
  }
]
