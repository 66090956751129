/* eslint-disable no-undef */
import { shippedAfterSaleAuditRO } from '@/api/financial/afterSaleProcessingOfOrders'
import { validators } from '@/utils'

export default {
  computed: {
    currAuditType() {
      return this.$route.path.split('/').pop() || 'server'
    },

    shippedOption() {
      return {
        option: {
          span: 8,
          menuBtn: false,
          column: [
            {
              label: '退款编号',
              prop: 'tradeNo',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '订单号',
              prop: 'orderCode',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '联系方式',
              prop: 'businessContactMobile',
              type: 'text'
            },
            {
              label: '申请原因',
              prop: 'applyReason',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '问题描述',
              prop: 'applyReasonDetail',
              placeholder: '暂无',
              type: 'text'
            },
            {
              label: '问题凭证',
              prop: 'receiptFilePathList',
              formslot: true
            },
            {
              label: '赔付类型',
              prop: 'compensateType',
              type: 'select',
              clearable: false,
              disabled: this.currAuditType === 'finance' ? true: false,
              formslot: false
            },
            {
              label: '退款金额',
              prop: 'amount',
              offset: 8,
              pull: 8,
              placeholder: '暂无',
              type: 'clickInput',
              formatter(val) {
                return `￥${val}`
              },
              rules: [
                {
                  validator: validators.money
                }
              ]
            },
            {
              label: '责任归属',
              prop: 'responseType',
              type: 'select',
              clearable: false,
              disabled: this.currAuditType === 'finance' ? true: false,
              rules: [
                {
                  required: true
                }
              ]
            },
            {
              label: '退款方式',
              prop: 'refundType',
              offset: 8,
              pull: 8,
              type: 'radio'
            }
            // {
            //   label: '备注',
            //   prop: 'refundRemark',
            //   placeholder: '单行输入',
            //   span: 16,
            //   type: 'textarea',
            //   minRows: 4,
            //   showWordLimit: true,
            //   maxlength: 100
            // }
          ]
        },
        doFn: async () => {
          let { form, oForm } = this
          oForm.receiptFilePathList = (oForm.receiptFilePathList || []).filter((item) => !!item)
          form.receiptFilePathList = (form.receiptFilePathList || []).filter((item) => !!item)
          let refundRemark = form.refundRemark
          form = this.validateDiffData(form, oForm)
          form.id = oForm.id
          form.refundRemark = refundRemark || ''
          return shippedAfterSaleAuditRO(form)
        }
      }
    }
  },
  watch: {
    'form.compensateType'(n, o) {
      const { form } = this
      if (n && form.isPayOrder) {
        let compensateAmount = form.$compensateAmount
        if (n == compensateAmount?.compensateType2) {
          form.amount = compensateAmount?.amount
          return
        }
        form.amount = compensateAmount?.[['internalTotalPrice', '$freight', '$totalPrice'][n - 1]]
      }
    }
  }
}
