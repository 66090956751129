/* eslint-disable */
import { WAIT_RELATION, WAYBILL_TYPE_HAND, WAYBILL_TYPE_SYS } from '@/utils/constant/businessConst'
import { accAdd, accSub, parsePrice, exportWrap } from '@/utils'
import { EXPRESS_WAYBILL_CREATING, ORDER_TYPE_SMT, ORDER_TYPE_FBA } from '@/utils/constant'
import { exportForCustomsAll, exportNotRelation } from '@/api/order'
import { find } from 'lodash'

// 跟业务端订单管理 货款总计保持同步
export function isPaidOrder({ paymentTime, $isPaidOrder }) {
  return !!paymentTime || $isPaidOrder
}

export function isWaybillIsCreatingOrder({ expressWaybillIsCreated }) {
  return expressWaybillIsCreated === EXPRESS_WAYBILL_CREATING
}

/**
 * @description:  只有系统生成运单需要显示运费（外部导入显示'不在重量范围内'）
 * @param:
 * @return:
 */
export function getPriceDetail(row) {
  let {
    orderType,
    templateFreight,
    couponAmount,
    internalTotalPrice,
    realTemplateFreight,
    realInternalTotalPrice,
    paymentFreightStatus
  } = row
  // 付款后显示优惠价格
  // if (isPaidOrder(row)) {
  //   templateFreight = orderType != 2 ? realTemplateFreight : templateFreight
  //   internalTotalPrice = realInternalTotalPrice
  // }
  return {
    paymentFreightStatus,
    templateFreight,
    couponAmount,
    internalTotalPrice
  }
}
/**
 * @description: 获取订单运费
 * @param: row {Object} 订单对象
 * @param: showExpressThird {Boolean} 是否显示无忧运费
 */
export function getFreight(row, showExpressThird = true, isOriginFreight = true) {
  if (row.orderType === ORDER_TYPE_SMT) {
    return 0
  }

  showExpressThird = showExpressThird && row.orderType === ORDER_TYPE_SMT
  if ((row.expressCompanyName || '').indexOf('无忧物流') != -1) {
    showExpressThird = true
  }

  if (!showExpressThird && row.waybillType === WAYBILL_TYPE_HAND) return '不用支付运费'

  if (showExpressThird) {
    try {
      let fee = JSON.parse(row.expressThird)?.trial_result
      return fee.toUpperCase().replace('CN', '')
    } catch (error) {
      return parsePrice(0)
    }
  }

  let { templateFreight, paymentFreightStatus } = isOriginFreight ? row : getPriceDetail(row)
  // templateFreight初始值为null，为0时表示不在物流方式运费模板重量范围内
  if (!paymentFreightStatus && row.orderType === ORDER_TYPE_FBA && row.paymentType !== '免支付') {
    return parsePrice(0)
  }
  return Number(templateFreight) || (templateFreight === 0 && (row.expressCompanyId || row.expressCompanyName))
    ? parsePrice(templateFreight)
    : templateFreight === null ? '- -' : '不在重量范围内'
}
// 货款总计
export function getTotalPrice(row) {
  let { templateFreight, internalTotalPrice, couponAmount, paymentFreightStatus } = getPriceDetail(row)

  if (row.orderType === ORDER_TYPE_SMT) {
    let fee = 0
    // try {
    //   let data = JSON.parse(row.expressThird)?.trial_result
    //   fee = data.slice(data.indexOf('￥') + 1, data.length)
    // } catch (error) {}
    return parsePrice(accSub(accAdd(internalTotalPrice, fee), couponAmount))
  }

  if (!Number(internalTotalPrice) || templateFreight === null) return '- -'
  if (!paymentFreightStatus && row.orderType === ORDER_TYPE_FBA && row.paymentType !== '免支付') {
    // 运费未支付
    templateFreight = 0
  }
  //if (row.waybillType !== WAYBILL_TYPE_SYS && row.orderType !== 2) return parsePrice(internalTotalPrice)
  return parsePrice(accSub(accAdd(internalTotalPrice, templateFreight), couponAmount))
}
export async function selectExportType(selectionData, that = this) {
  const exportTypeDic = [
    {
      label: '按订单导出',
      value: 1,
      api: exportForCustomsAll,
      data: {
        // 导出数据类型 1为按单导出，2为按产品导出
        exportType: 1
      }
    },
    {
      label: '按产品导出',
      value: 2,
      api: exportForCustomsAll,
      data: {
        // 导出数据类型 1为按单导出，2为按产品导出
        exportType: 2
      }
    }
  ]
  // let waitRelation = Number(WAIT_RELATION)
  // if (selectionData?.length > 0 && selectionData.every((item) => item.orderStatus === waitRelation)) {
  //   exportTypeDic.push({
  //     label: '按是否关联',
  //     value: 3,
  //     api: exportNotRelation
  //   })
  // }

  let form = await that.$dialogForm({
    title: '导出订单',
    confirmText: '导出',
    option: {
      hideRequiredAsterisk: true,
      menuBtn: false,
      span: 24,
      column: [
        {
          label: '导出方式',
          prop: 'exportType',
          type: 'radio',
          dicData: exportTypeDic,
          rules: [
            {
              required: true
            }
          ]
        }
      ]
    }
  })
  console.log('form', form)
  return form && find(exportTypeDic, { value: form.exportType })
}
export async function orderExport(selectionData, that = this, params) {
  // if (selectionData.length === 0) {
  //   that.$message.warning('请选择订单')
  //   return
  // }

  let curItem = await selectExportType(selectionData, that)
  console.log(curItem)
  params = params || {}
  if (!curItem) return
  // console.log(curItem)

  return exportWrap(
    curItem.api({
      ...curItem.data,
      ...params,
      idList: selectionData.length === 0 ? undefined : selectionData.map((item) => item.id || item?.orderInfo?.id),
      // 0为再次导出，1为第一次导出
      isFirstExport: 0,
      isDeleted: '0'
      // isCustomsDeclaredExported: 0,
      // 导出类型 1为业务端导出，3为工厂端导出
      // exportType: 1
    }),
    '导出订单'
  )
}

export function expressExpireTimeDetail(time) {
  if (!time) return ''
  let { divide } = this.$math
  let parseTime = this.$moment(time).diff(this.$moment(), 's')
  let str = parseTime >= 0 ? '剩余发货：' : '已到期：'
  parseTime = Math.abs(parseTime)
  if (parseTime <= 3600) {
    return `${str}${divide(parseTime, 60).toFixed(2)}分钟`
  } else {
    parseTime = divide(parseTime, 3600)
    let d = Math.floor(divide(parseTime, 24))
    let H = (parseTime % 24).toFixed(2)
    return `${str}${d > 0 ? `${d}天` : ''}${H > 0 ? `${H}小时` : ''}`
  }
}
