import request from '@/service/request'

// 未生产售后申请显示
export function notProducedAfterSaleList(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/notProducedAfterSaleList',
    method: 'post',
    data
  })
}

// 已生产售后申请显示
export function producedAfterSaleList(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/producedAfterSaleList',
    method: 'post',
    data
  })
}

// 已发货售后list
export function delivedAfterSalelist(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/DelivedAfterSalelist',
    method: 'post',
    data
  })
}

// 订单售后审核数据回显
export function afterShippedToExamine(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/AfterShippedToExamine',
    method: 'post',
    data
  })
}

// 查看审核数据接口
export function afterShippedInfo(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/AfterShippedInfo',
    method: 'post',
    data
  })
}

// 退款原因显示
export function refundSeasonList(data) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/refundSeasonList',
    method: 'post',
    data
  })
}
// 退款原因设置
export function createRefundSeason(data) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/createRefundSeason',
    method: 'post',
    data
  })
}
// 退款原因修改
export function updateRefundSeason(data) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/updateRefundSeasonList',
    method: 'post',
    data
  })
}
// 退款原因删除
export function deleteRefundSeason(data) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/deleteRefundSeasonList',
    method: 'post',
    data
  })
}

// 未生产售后审核
export function notProducedAfterSaleAudit(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/notProducedAfterSaleAudit',
    method: 'post',
    data
  })
}
// 已生产售后审核
export function producedAfterSaleAudit(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/producedAfterSaleAudit',
    method: 'post',
    data
  })
}
// 已发货售后审核
export function shippedAfterSaleAuditRO(data) {
  return request({
    url: '/externaladmin/orderService/afterSaleOrder/shippedAfterSaleAudit',
    method: 'post',
    data
  })
}

// 导出审核财务订单
export function exportOrder(data) {
  return request({
    url: '/externaladmin/orderService/financialAfterSalesAudit/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 导出审核财务订单-新
export function exportOrderNew(data) {
  return request({
    url: '/externaladmin/orderService/shippedAfterSales/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
